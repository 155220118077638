import React, { ReactNode } from "react";
import { PageProvider } from "@src/components/page-provider/PageProvider";
import { useLoadTranslations } from "@src/components/page-provider";
import { TRANSLATIONS, TranslationType } from "./translations";
import { useTranslations as useOriginalTranslations } from "@src/components/page-provider/usePage";

export const Provider = ({ children }: { children: ReactNode }) => {
  const t = useLoadTranslations("directus.page_dojo_safe", TRANSLATIONS);

  return (
    <PageProvider translations={t} images={{}}>
      {children}
    </PageProvider>
  );
};

export const withProvider = <T,>(Component: React.FC<T>) => {
  const Wrapper = (props: T) => (
    <Provider>
      <Component {...(props as any)} />
    </Provider>
  );

  return Wrapper;
};

const useTranslations = useOriginalTranslations as typeof useOriginalTranslations<TranslationType>;

export { useTranslations };
